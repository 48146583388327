<template>
  <div>
    <!-- button -->
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-prevent-closing
      variant="outline-primary"
    >
      Add Category
    </b-button>

    <!-- modal data -->

    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Add new Category"
      ok-title="Create"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="CategoryInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Category Details</h5>
              <small class="text-muted"> Enter the Category Details. </small>
            </b-col>
            <b-col md="12">
              <b-form-group label="title" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="title"
                    v-model="Category.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Category title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Category Cover Image"
                label-for="CorseCoverImage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CorseCoverImage"
                  rules="required"
                >
                  <b-form-file
                    :state="Boolean(Category.imageCover)"
                    id="CorseCoverImage"
                    accept="image/*"
                    v-model="Category.image"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CategorysApi from "@/apiServices/categoriesApi";
import imagesApi from "@/apiServices/imagesApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    required,
    email,
    max_value,
    numeric,
    name: "",
    nameState: null,
    options: ["video", "pdf", "powerpoint"],
    submittedNames: [],
    Category: {
      title: "",
      description: "",
      type: "",
      imageCover: null,
    },
  }),
  methods: {
    createCategorys(CategoryFormData) {
      CategorysApi.createCategory(CategoryFormData).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Category Created",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    createCategoryValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.CategoryInfoRules.validate().then((success) => {
          if (success) {
            const imageFormData = new FormData();
            imageFormData.append("image", this.Category.image);
            imagesApi.uploadImage(imageFormData).then((response) => {
              console.log(response);
              this.Category.image = response;
              this.createCategorys(this.Category);
              this.$refs["my-modal"].toggle("#toggle-btn");
              this.Category = {};
            });

            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.createCategoryValidation();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>
