<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span>Edit</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <b-modal
      ref="my-modal"
      modal-class="modal-info"
      hide-footer
      title="update Category"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="CategoryInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Category Details</h5>
              <small class="text-muted"> Enter the Category Details. </small>
            </b-col>
            <b-col md="6">
              <b-form-group label="title" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="title"
                    v-model="Category.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Category Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Category Cover Image"
                label-for="CorseCoverImage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CorseCoverImage"
                  rules="required"
                >
                  <b-form-file
                    :state="Boolean(Category.image)"
                    id="CorseCoverImage"
                    accept="image/*"
                    v-model="Category.image"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </form>
      <b-row variant="info">
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-secondary"
            block
            @click="hideModal"
          >
            No
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-info"
            block
            @click="toggleModal"
          >
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span class="mr-50 text-info">update</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CategoryiesApi from "@/apiServices/categoriesApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import imagesApi from "@/apiServices/imagesApi";

export default {
  props: ["Category"],

  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      options: ["video", "pdf", "powerpoint"],

      prams: {
        id: "",
      },
    };
  },
  created() {
    // console.log(this.Category);
    // this.Category.image = null;
  },
  methods: {
    updateCategory() {
      CategoryiesApi.updateCategory(this.Category).then((response) => {
        if (response.data) {
          // this.course = response.data;
          // console.log(response.data);
          this.$emit("updateCategoryFromlist", this.id);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Category updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    showModal() {
      console.log(this.id + "this id");

      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      if (this.Category.image instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("image", this.Category.image);

        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          this.Category.image = response;
          this.updateCategory();
        });
      } else {
        delete this.Category.image;
        this.updateCategory();
      }
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
  },
};
</script>
